import * as React from 'react';
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { withRouter } from '../common/with-router';

function RemoveMouse(props) {
  const selectedMouseIds = props.selections; // Get all selected mouse IDs
  const selectedMice = props.mice.filter((mouse) => selectedMouseIds.includes(mouse.id)); // Find all selected mice

  const handleRemove = () => {
    // Loop through each selected mouse and call onSubmit
    selectedMice.forEach((mouse) => {
      props.onSubmit(mouse);
    });

    // Close the dialog
    props.onClose();
  };

  return (
    <>
      <DialogTitle alignSelf={"center"}>Confirm Delete</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Are you sure you want to delete the following mice?
        </Typography>
        {selectedMice.length > 0 ? (
          selectedMice.map((mouse) => (
            <div key={mouse.id} style={{ marginBottom: '10px' }}>
              <Typography variant="body2">
                <strong>ID:</strong> {mouse.id || "N/A"}
              </Typography>
              <Typography variant="body2">
                <strong>Strain:</strong> {mouse.strain || "N/A"}
              </Typography>
              <Typography variant="body2">
                <strong>Gender:</strong> {mouse.gender || "N/A"}
              </Typography>
              <Typography variant="body2">
                <strong>Date of Birth:</strong> {mouse.dob || "N/A"}
              </Typography>
            </div>
          ))
        ) : (
          <Typography variant="body2" sx={{ marginTop: 2 }}>
            No mice selected.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button color="error" onClick={handleRemove}>
          Delete
        </Button>
      </DialogActions>
    </>
  );
}

export default withRouter(RemoveMouse);
