import React from 'react';
import { Box, Button, Paper, TextField, Typography } from '@mui/material';
import LoginService from '../services/login.service';
import { withRouter } from '../common/with-router';
import { useNavigate } from 'react-router-dom';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import LoginImage from '../common/logo.PNG';
import labLogo from '../common/haider_lab_grayscale.PNG'

function Login(props) {
    const navigate = useNavigate();
    const [credentials, setCredentials] = React.useState({ name: '', password: '' });
    const [failed, setFailed] = React.useState(false);
    const checkCredentials = async e => {
        e.preventDefault();
        // console.log(credentials)
        LoginService.checkUser(credentials)
        .then((response) => {
            // console.log(response)
            if (response.data.success) {
                console.log("Response data success")
                const { permissions } = response.data;
                props.setToken(true, credentials.name, permissions);
                props.setUser(credentials.name);
                navigate('/');
            } else {
                setFailed(true);
            }
        })
        .catch(err => {
            console.log(err.message)
            setFailed(true);
        })
    };

    const handleChange = e => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
    };

    React.useEffect(() => {
        setFailed(false);
    }, [credentials.name, credentials.password]);

    return(
        <Box 
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'top',
            height: '98vh',
            alignItems: 'center',
            rowGap: 2,
            marginTop: 0,
            color: 'rgba(245, 245, 245)'
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%'
            }}>
                <Box sx={{
                    display: 'flex',
                    alignContent: 'left'
                }}>
                    <a href="https://haider.gatech.edu/">
                        {/* <img src='https://cdn.glitch.global/345b4a37-e5cb-4b8c-bcd6-71b41bee8341/haiderlab_grayscale.PNG?v=1686534721846' 
                            alt='haider lab logo' height='45'/> */}
                            <img src={labLogo} alt="lab logo" height='45'/>
                    </a>
                </Box>
            </Box>
            {/* Login form container */}
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: 525,
                    height: 525,
                    borderRadius: 9,
                    boxShadow: 10,
                    padding: 4,
                    mt: '10vh',
                }}
            >
                {/* Center logo */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginBottom: 4,
                    }}
                >
                    <img src={LoginImage} alt="Mousemate logo" height="175" />
                    <Typography variant="h5" color="black" sx={{ fontWeight: 450, mt: 2 }}>
                        Sign in with Mousemate ID
                    </Typography>
                </Box>

                {/* Input fields */}
                <TextField
                    label="Username"
                    name="name"
                    value={credentials.name}
                    onChange={handleChange}
                    margin="normal"
                    error={failed}
                    sx={{
                        '& .MuiOutlinedInput-root': { borderRadius: 4 },
                        width: '80%', marginBottom: 2
                    }}
                />
                <TextField
                    label="Password"
                    type="password"
                    name="password"
                    value={credentials.password}
                    onChange={handleChange}
                    margin="normal"
                    error={failed}
                    helperText={failed ? "Invalid username or password" : ""}
                    sx={{
                        '& .MuiOutlinedInput-root': { borderRadius: 4 },
                        width: "80%",
                        marginBottom: 2
                    }}
                />
                <Button
                    variant="contained"
                    sx={{ mt: 2 }}
                    onClick={checkCredentials}
                    endIcon={<ArrowCircleRightOutlinedIcon />}
                >
                    Login
                </Button>
            </Paper>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignContent: 'center',
                
            }}>
            </Box>
        </Box>
    )
}

export default withRouter(Login)