import * as React from 'react';
import Title from './title.component';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { withRouter } from '../common/with-router';
import MouseService from '../services/mouse.service';
import ageFromDate from '../common/ageFromDate';
import { Typography } from '@mui/material';

function createRows(mice) {
    if (!mice) {
        return [];
    }
    return mice.map((mouse) => ({
        roomId: mouse.roomId || '',
        id: mouse.id || '',
        cageId: mouse.cageId || 0,
        strain: mouse.strain || '',
        claim: mouse.claim || '',
        claimType: mouse.claimType || '',
        status: mouse.status || '',
        gender: mouse.gender || '',
        age: mouse.dob ? ageFromDate(mouse.dob) : null,
        generation: mouse.generation || '',
        notes: mouse.notes || '',
    }));
}

function EuthCageList(props) {
    const [tableRows, setTableRows] = React.useState([]);
    const [selectedMice, setSelectedMice] = React.useState(new Set());
    const [sortModel, setSortModel] = React.useState([{ field: 'id', sort: 'desc' }]);
    const [dialogOpen, setDialogOpen] = React.useState(false);

    React.useEffect(() => {
        MouseService.getInactiveMouse()
            .then((response) => {
                setTableRows(createRows(response.data));
            })
            .catch((e) => {
                console.error('Error fetching inactive mice:', e);
            });
    }, [props.reload]);

    const handleCheckboxChange = (mouseId) => {
        setSelectedMice((prevSelected) => {
            const newSelection = new Set(prevSelected);
            if (newSelection.has(mouseId)) {
                newSelection.delete(mouseId);
            } else {
                newSelection.add(mouseId);
            }
            return newSelection;
        });
    };

    const handleRestore = () => {
        if (selectedMice.size === 0) {
            alert('No mice selected for restoration.');
            return;
        }

        const selectedMouseIds = Array.from(selectedMice);

        MouseService.restoreMice(selectedMouseIds)
            .then((response) => {
                alert(response.data.message);
                setSelectedMice(new Set());
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    alert(`Error: ${error.response.data.message}`);
                } else {
                    alert('An unexpected error occurred.');
                }
                console.error('Error restoring mice:', error);
            });
    };

    const openConfirmationDialog = () => {
        setDialogOpen(true);
    };

    const closeConfirmationDialog = () => {
        setDialogOpen(false);
    };

    const confirmRestore = () => {
        closeConfirmationDialog();
        handleRestore(); // Proceed with the restore action
    };

    const columns = [
        {
            field: 'select',
            headerName: '',
            width: 50,
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <Checkbox
                    checked={selectedMice.has(params.row.id)}
                    onChange={() => handleCheckboxChange(params.row.id)}
                />
            ),
        },
        { field: 'cageId', headerName: 'Cage ID', width: 100 },
        { field: 'id', headerName: 'Mouse #', width: 100 },
        { field: 'strain', headerName: 'Strain', width: 155 },
        { field: 'status', headerName: 'Euthanized', width: 100 },
        { field: 'gender', headerName: 'Sex', width: 65 },
        { field: 'age', headerName: 'Age', width: 65 },
        { field: 'notes', headerName: 'Cage Notes', width: 375 },
        { field: 'claim', headerName: 'Claimant', width: 85 },
        { field: 'claimType', headerName: 'Claim', width: 125 },
    ];

    return (
        <React.Fragment>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 1,
                }}
            >
                <Title>All Euthanized Mice</Title>
            </Box>
            <Box>
                <DataGrid
                    rows={tableRows}
                    columns={columns}
                    disableRowSelectionOnClick
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                    rowHeight={40}
                    sx={{
                        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                            outline: "none !important",
                        },
                        "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
                            outline: "none",
                        },
                        "&.MuiDataGrid-root .MuiDataGrid-cell": {
                            whiteSpace: "normal !important",
                            wordWrap: "break-word !important",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: "#f5f5f5",
                            fontWeight: "bold",
                            fontSize: "0.9rem",
                            textAlign: "center",
                        },
                        "& .MuiDataGrid-row": {
                            "&:nth-of-type(odd)": {
                                backgroundColor: "#fafafa",
                            },
                            "&:hover": {
                                backgroundColor: "#f1f1f1",
                            },
                        },
                        "& .MuiDataGrid-columnHeader": {
                            textAlign: "center",
                        },
                        "& .MuiDataGrid-cell": {
                            padding: "8px",
                        },
                        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                            height: "8px",
                        },
                        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
                            backgroundColor: "#e0e0e0",
                            borderRadius: "4px",
                        },
                        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
                            backgroundColor: "#bdbdbd",
                            borderRadius: "4px",
                        },
                        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
                            backgroundColor: "#9e9e9e",
                        },
                        fontFamily: "'Roboto', 'Arial', sans-serif",
                        maxHeight: "67vh",
                        borderRadius: "8px",
                        border: "1px solid #e0e0e0",
                    }}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    mt: 2,
                }}
            >
                <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={openConfirmationDialog}
                    disabled={selectedMice.size === 0}
                    sx={{
                        borderRadius: 2,
                        fontWeight: 'bold',
                        padding: '8px 16px',
                        textTransform: 'uppercase',
                        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
                        backgroundColor: selectedMice.size > 0 ? '#1976d2' : '#bdbdbd',
                        color: selectedMice.size > 0 ? '#fff' : '#757575',
                        '&:hover': {
                            backgroundColor: selectedMice.size > 0 ? '#115293' : '#bdbdbd',
                        },
                    }}
                >
                    Restore Mice
                </Button>
            </Box>
            <Dialog open={dialogOpen} onClose={closeConfirmationDialog} maxWidth="sm" fullWidth>
                <DialogTitle align="center" sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
                    Confirm Restore
                </DialogTitle>
                <DialogContent>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            rowGap: 2,
                            padding: '16px 0',
                        }}
                    >
                        <Typography variant="body1" sx={{ fontSize: '1.1rem', color: 'text.secondary' }}>
                            Are you sure you want to restore the selected mice?
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions
                    sx={{
                        justifyContent: 'center',
                        padding: '16px',
                    }}
                >
                    <Button
                        onClick={closeConfirmationDialog}
                        color="secondary"
                        variant="outlined"
                        sx={{
                            textTransform: 'uppercase',
                            padding: '8px 16px',
                            fontWeight: 'bold',
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={confirmRestore}
                        color="primary"
                        variant="contained"
                        sx={{
                            textTransform: 'uppercase',
                            padding: '8px 16px',
                            fontWeight: 'bold',
                        }}
                    >
                        Yes, Restore
                    </Button>
                </DialogActions>
            </Dialog>

        </React.Fragment>
    );
}

export default withRouter(EuthCageList);
